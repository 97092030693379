<template>
  <div :class="{ relative: setRootPosition }">
    <div v-if="isHalloweenThemeEnabled">
      <template v-if="size == 'lg'">
        <Bat class="pictogram absolute left-[5px] top-[-4px] size-10" />
        <Bat
          class="pictogram absolute left-[55px] top-[60px] size-10 rotate-[-30deg]"
        />
      </template>
      <template v-else>
        <Bat class="absolute left-[40px] top-[52px] md:left-16 md:top-14" />
      </template>
    </div>
    <Badge :class="[computedSize, colorClass, { '-scale-x-100': flip }]" />
    <div
      class="absolute inset-0 flex flex-col items-center justify-center font-title text-white"
    >
      <span
        :class="
          (['text-4xl', textClass], { 'mt-1 mb-[3px]': showCampaignText })
        "
        :style="{ fontSize: computedFontSize }"
      >
        {{ campaignAmount }}
      </span>
      <span
        v-if="showCampaignText"
        class="mt-1 text-center font-sans text-[12px] leading-none"
      >
        prova-på-<br />rabatt
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import Badge from '@brand/assets/images/campaign.svg?component';
import Bat from '~/assets/pictograms/dynamic/bat.svg?component';

const enabledFeaturesStore = useEnabledFeaturesStore();
const isHalloweenThemeEnabled =
  enabledFeaturesStore.isFeatureEnabled('halloweenTheme');

type Size = 'sm' | 'md' | 'lg';

const props = defineProps({
  campaignAmount: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<Size>,
    default: 'md',
  },
  colorClass: {
    type: String,
    default: 'bg-supporting-price',
  },
  textClass: {
    type: String,
    default: '',
  },
  setRootPosition: {
    type: Boolean,
    default: true,
  },
  flip: {
    type: Boolean,
    default: false,
  },
  responsive: {
    type: Boolean,
    default: true,
  },
  showCampaignText: {
    type: Boolean,
    default: false,
  },
});

const sizeClasses: {
  static: Record<Size, string>;
  responsive: Record<Size, string>;
} = {
  static: {
    sm: 'w-20',
    md: 'w-24',
    lg: 'w-28',
  },
  responsive: {
    sm: 'w-16 md:w-20',
    md: 'w-20 md:w-24',
    lg: 'w-24 md:w-28',
  },
};

const fontSizes: Record<Size, number> = {
  sm: 28,
  md: 34,
  lg: 40,
};

const computedFontSize = computed(() => {
  const baseSize = fontSizes[props.size];
  const fontSize = baseSize - props.campaignAmount.length * 2;
  return `${fontSize}px`;
});

const computedSize = computed(() => {
  if (!props.responsive) {
    return sizeClasses.static[props.size];
  }

  return sizeClasses.responsive[props.size];
});
</script>
